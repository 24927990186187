import React from "react";
import { useStaticQuery, graphql } from "gatsby";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import BusinessSavingsOverview from "../../../components/savings-overview/business-savings-overview";
import ContactInfo from "../../../components/contact-info/contact-info";
import StretchedLinkCards from "../../../components/card/stretched-link-cards";
import BusinessSavingsOverviewCDs from "../../../components/savings-overview/business-savings-overview-cds";
import VimeoVideoFrame from "../../../components/custom-widgets/vimeo-video-frame";
import List from "../../../components/custom-widgets/list";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import useCardBlogsData from "../../../hooks/use-card-blogs-data";
import getFeaturedCardBlogData from "../../../helpers/getFeaturedCardBlogData";

const BusinessSavingsAccount = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/business-banking/business-savings-account/hero-business-savings-02152023-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/business-banking/business-savings-account/hero-business-savings-02152023-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/business-banking/business-savings-account/hero-business-savings-02152023-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/business-banking/business-savings-account/hero-business-savings-02152023-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/business-banking/business-savings-account/hero-business-savings-02152023-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/business-banking/business-savings-account/hero-business-savings-02152023-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/business-banking/business-savings-account/hero-business-savings-02152023-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "business-savings-account-hero",
    ...getHeroImgVariables(imgData),
    altText: "Man working on a bike in a bicycle repair shop.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "We Make Saving Easy, so You Can Focus On What's Really Important"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/business-banking",
      title: "Business Banking"
    },
    {
      id: 2,
      active: true,
      title: "Business Savings"
    }
  ];

  const SEOData = {
    title: "Business Savings Accounts",
    meta: [
      {
        name: "title",
        property: "og:title",
        content: "Business Savings Accounts"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Save for the future and grow your business with business savings accounts from WaFd Bank. Learn more about your business savings options today."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/business-banking/business-savings-account"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-business-savings-02152023.jpg"
      }
    ]
  };

  const cardsData = useCardBlogsData([
    "/blog/small-business/what-is-needed-to-open-business-bank-account",
    "/blog/small-business/is-starting-a-business-worth-it",
    "/blog/small-business/what-is-the-best-bank-for-a-small-business"
  ]);

  const featuredCardsData = getFeaturedCardBlogData(cardsData, { hasText: false });

  const articleCardsData = {
    cards: featuredCardsData
  };

  const treasuryExpressVideoData = {
    vimeoId: "399263384",
    allowFeatures: "fullscreen",
    title: "WAFD Treasury Express App User Guide",
    class: "m-auto iframe w-100 border-radius-12"
  };

  const onlineBusinessBankingListData = {
    greenCircleCheckmark: true,
    accountName: "business-banking-list",
    class: "mb-0",
    items: [
      {
        id: 1,
        text: "Create &amp; manage sub-users"
      },
      {
        id: 2,
        text: "Check account balances and activity"
      },
      {
        id: 3,
        text: "Place stop payments"
      },
      {
        id: 4,
        text: "Transfer funds between accounts"
      },
      {
        id: 5,
        text: "View cleared check images"
      },
      {
        id: 6,
        text: "Access eStatements"
      },
      {
        id: 7,
        text: "Pay bills with small business bill pay"
      },
      {
        id: 8,
        text: "Make deposits",
        class: "mb-0"
      }
    ]
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section id="business-savings-intro-section" className="container pb-0">
        <h1>Business Savings</h1>
        <h3 className="text-success mb-0">We'll help with flexible, competitive accounts to meet your needs.</h3>
      </section>
      <BusinessSavingsOverview />
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <VimeoVideoFrame {...treasuryExpressVideoData} />
            </div>
            <div className="col-md-6">
              <h2>Online Business Banking</h2>
              <p className="font-weight-bold">Through our user-friendly, online platform you can:</p>
              <List {...onlineBusinessBankingListData} />
            </div>
          </div>
        </div>
      </section>
      <BusinessSavingsOverviewCDs />
      <StretchedLinkCards {...articleCardsData} />
      <ContactInfo type="business" bgClass="bg-white" />
    </SecondaryLanding>
  );
};

export default BusinessSavingsAccount;
