import React, { useState } from "react";
import { Link } from "gatsby";
import Collapse from "react-bootstrap/Collapse";

import Button from "../custom-widgets/button";

// Styles
import styles from "./savings-overview.module.scss";

const BusinessSavingsOverviewCDs = () => {
  const [collapse19MoCd, setCollapse19MoCd] = useState(false);
  const [collapse13MoCd, setCollapse13MoCd] = useState(false);
  const [collapseBusCd, setCollapseBusCd] = useState(false);
  const [collapse7MoCd, setCollapse7MoCd] = useState(false);
  const [collapse4to5MoCd, setCollapse4to5MoCd] = useState(false);

  const locationsBtnData = {
    type: "link",
    class: "btn-primary w-100 w-sm-auto w-lg-100",
    containerClass: "mb-4 mb-lg-0",
    text: "Open in Branch",
    url: "/locations",
    showIcon: false
  };

  const interestRatesBtnData = {
    containerClass: "text-center",
    type: "link",
    class: "btn-link w-100 w-sm-auto w-lg-100",
    text: "Business Interest Rates/APY",
    url: "/business-banking/business-cd-money-market-rates",
    showIcon: false
  };

  const fourToFiveMonthCdsBtnData = {
    externalUrl: true,
    target: "blank",
    class: "btn-primary w-100 w-sm-auto w-lg-100",
    containerClass: "mb-4 mb-lg-0",
    text: "Open in Branch",
    url: "/locations",
    type: "link",
    showIcon: false
  };

  const sevenMonthCdsBtnData = {
    externalUrl: true,
    target: "blank",
    class: "btn-primary w-100 w-sm-auto w-lg-100",
    containerClass: "mb-4 mb-lg-0",
    text: "Open an Account",
    showIcon: false,
    url: "https://wafd.my.site.com/nPortal__PortalLogin?startURL=%2fnportal__portal%3Fapp%3Dcustomer-portal&core=es5"
  };

  const thirteenMonthCdsBtnData = {
    externalUrl: true,
    target: "blank",
    class: "btn-primary w-100 w-sm-auto w-lg-100",
    containerClass: "mb-4 mb-lg-0",
    text: "Open an Account",
    showIcon: false,
    url: "https://wafd.my.site.com/nPortal__PortalLogin?startURL=%2fnportal__portal%3Fapp%3Dcustomer-portal&core=es5"
  };

  const nineteenMonthCdsBtnData = {
    externalUrl: true,
    target: "blank",
    class: "btn-primary w-100 w-sm-auto w-lg-100",
    containerClass: "mb-4 mb-lg-0",
    text: "Open an Account",
    showIcon: false,
    url: "https://wafd.my.site.com/nPortal__PortalLogin?startURL=%2fnportal__portal%3Fapp%3Dcustomer-portal&core=es5"
  };

  return (
    <section id="business-savings-overview-section-cds" className="container">
      <h2>Certificates of Deposits</h2>
      {/* HIDDEN XS & SM & MD*/}
      <div className="d-none d-lg-block">
        <div className="row">
          <div className="col">
            <span className="sr-only">Compare business savings account details table</span>
            <table id="savings-overview-table-cds-1" className="table table-bordered table-fixed mb-3 mb-lg-4">
              <thead>
                <tr className="h4-font-size">
                  <th title="Business 4-5 Month CD Special">
                    <div>
                      <strong>Business 4-5 Month CD Special</strong>
                    </div>
                  </th>
                  <th title="Business 7 Month CD Special">
                    <div>
                      <strong>Business 7 Month CD Special</strong>
                    </div>
                  </th>
                  <th title="Business 13 Month CD Special">
                    <div>
                      <strong>Business 13 Month CD Special</strong>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center">
                    <Button id="cta-four-to-five-month-cds-desktop" {...fourToFiveMonthCdsBtnData} />
                  </td>
                  <td className="text-center">
                    <Button id="cta-seven-month-cds-desktop" {...sevenMonthCdsBtnData} />
                  </td>
                  <td className="text-center">
                    <Button id="cta-thirteen-month-cds-desktop" {...thirteenMonthCdsBtnData} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>$1,000 minimum to open</strong>
                  </td>
                  <td>
                    <strong>$1,000 minimum to open</strong>
                  </td>
                  <td>
                    <strong>$1,000 minimum to open</strong>
                  </td>
                </tr>
                <tr>
                  <td>Minimum to earn interest is $1,000</td>
                  <td>Minimum to earn interest is $1,000</td>
                  <td>Minimum to earn interest is $1,000</td>
                </tr>
                <tr>
                  <td>
                    With Eligible<sup>&dagger;</sup> Checking or $25k New Money
                  </td>
                  <td>
                    With Eligible<sup>&dagger;</sup> Checking or $25k New Money
                  </td>
                  <td>
                    With Eligible<sup>&dagger;</sup> Checking or $25k New Money
                  </td>
                </tr>
                <tr>
                  <td>
                    <Button id="cd-special-1-business-rates-desktop" {...interestRatesBtnData} />
                  </td>
                  <td>
                    <Button id="cd-special-2-business-rates-desktop" {...interestRatesBtnData} />
                  </td>
                  <td>
                    <Button id="cd-special-3-business-rates-desktop" {...interestRatesBtnData} />
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              id="savings-overview-table-cds-2"
              className="table table-bordered table-fixed"
              style={{ border: "none" }}
            >
              <thead>
                <tr className="h4-font-size">
                  <th title="Business 19 Month CD Special">
                    <div>
                      <strong>Business 19 Month CD Special</strong>
                    </div>
                  </th>
                  <th title="Business CDs">
                    <div>
                      <strong>Business CDs</strong>
                    </div>
                  </th>
                  <th style={{ border: "none" }}></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center">
                    <Button id="cta-nineteen-month-cds-desktop" {...nineteenMonthCdsBtnData} />
                  </td>
                  <td className="text-center">
                    <Button id="cta-apply-at-branch-desktop" {...locationsBtnData} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>$1,000 minimum to open</strong>
                  </td>
                  <td>
                    <strong>$1,000 minimum to open</strong>
                  </td>
                </tr>
                <tr>
                  <td>Minimum to earn interest is $1,000</td>
                  <td>Minimum to earn interest is $1,000</td>
                </tr>
                <tr>
                  <td>
                    With Eligible<sup>&dagger;</sup> Checking or $25k New Money
                  </td>
                  <td>
                    Terms from 3 months to 5 years.
                    <br />A penalty may be assessed for early withdrawal.
                  </td>
                </tr>
                <tr>
                  <td>
                    <Button id="cd-special-4-business-rates" {...interestRatesBtnData} />
                  </td>
                  <td>
                    <Button id="business-cds-rates" {...interestRatesBtnData} />
                  </td>
                </tr>
              </tbody>
            </table>
            <p id="eligible-checking-disclaimer" className="text-sm text-muted mb-0">
              <sup>&dagger;</sup>Eligible checking accounts include Simple Business or Business Interest Checking
            </p>
          </div>
        </div>
      </div>

      {/* VISIBLE XS & SM */}
      <div className="mb-3 d-block d-lg-none">
        {/* Business 4-5 Month CD Special */}
        <article id="m-4-to-5month-cd" className="mb-3 border-bottom">
          <h2>
            <strong>Business 4-5 Month</strong> CD Special
          </h2>
          <div className={`${styles.mobileDetailsItem} font-weight-bold`}>Minimum to earn interest is $1,000</div>
          <div
            id="cd-special-toggle-four-to-five-month-cds"
            onClick={() => {
              setCollapse4to5MoCd(!collapse4to5MoCd);
            }}
            className={`${styles.mobileDetails} ${
              collapse4to5MoCd ? styles.mobileDetailsOpen : styles.mobileDetailsClosed
            }`}
            aria-controls="cd-special-details-four-to-five-month-cds"
            aria-label="Show/Hide Details"
            aria-expanded={collapse4to5MoCd}
            role="button"
          ></div>
          {/* "Show/Hide Details" via CSS ::after content */}
          <Collapse in={collapse4to5MoCd}>
            <div id="cd-special-details-four-to-five-month-cds" className="collapse">
              <div className={`${styles.mobileDetailsItem} font-weight-bold`}>$1,000 minimum to open</div>
              <div className={styles.mobileDetailsItem}>Minimum to earn interest is $1,000</div>
              <div className={styles.mobileDetailsItem}>
                With Eligible<sup>&dagger;</sup> Checking or $25k New Money
              </div>
              <div className={styles.mobileDetailsItem}>
                <Button id="m-cd-special-1-business-rates-mobile" {...interestRatesBtnData} />
              </div>
            </div>
          </Collapse>
          <Button id="cta-four-to-five-month-cds-mobile" {...fourToFiveMonthCdsBtnData} />
        </article>
        {/* Business 7 Month CD Special */}
        <article id="m-7month-cd" className="mb-3 border-bottom">
          <h2>
            <strong>Business 7 Month</strong> CD Special
          </h2>
          <div className={`${styles.mobileDetailsItem} font-weight-bold`}>Minimum to earn interest is $1,000</div>
          <div
            id="cd-special-toggle-seven-month-cds"
            onClick={() => {
              setCollapse7MoCd(!collapse7MoCd);
            }}
            className={`${styles.mobileDetails} ${
              collapse7MoCd ? styles.mobileDetailsOpen : styles.mobileDetailsClosed
            }`}
            aria-controls="cd-special-details-seven-month-cds"
            aria-label="Show/Hide Details"
            aria-expanded={collapse7MoCd}
            role="button"
          ></div>
          {/* "Show/Hide Details" via CSS ::after content */}
          <Collapse in={collapse7MoCd}>
            <div id="cd-special-details-seven-month-cds" className="collapse">
              <div className={`${styles.mobileDetailsItem} font-weight-bold`}>$1,000 minimum to open</div>
              <div className={styles.mobileDetailsItem}>Minimum to earn interest is $1,000</div>
              <div className={styles.mobileDetailsItem}>
                With Eligible<sup>&dagger;</sup> Checking or $25k New Money
              </div>
              <div className={styles.mobileDetailsItem}>
                <Button id="m-cd-special-2-business-rates-mobile" {...interestRatesBtnData} />
              </div>
            </div>
          </Collapse>
          <Button id="cta-seven-month-cds-mobile" {...sevenMonthCdsBtnData} />
        </article>
        {/* Business 13 Month CD Special */}
        <article id="m-13month-cd" className="mb-3 border-bottom">
          <h2>
            <strong>Business 13 Month</strong> CD Special
          </h2>
          <div className={`${styles.mobileDetailsItem} font-weight-bold`}>Minimum to earn interest is $1,000</div>
          <div
            id="cd-special-toggle-thirteen-month-cds"
            onClick={() => {
              setCollapse13MoCd(!collapse13MoCd);
            }}
            className={`${styles.mobileDetails} ${
              collapse13MoCd ? styles.mobileDetailsOpen : styles.mobileDetailsClosed
            }`}
            aria-controls="cd-special-details-thirteen-month-cds"
            aria-label="Show/Hide Details"
            aria-expanded={collapse13MoCd}
            role="button"
          ></div>
          {/* "Show/Hide Details" via CSS ::after content */}
          <Collapse in={collapse13MoCd}>
            <div id="cd-special-details-thirteen-month-cds" className="collapse">
              <div className={`${styles.mobileDetailsItem} font-weight-bold`}>$1,000 minimum to open</div>
              <div className={styles.mobileDetailsItem}>Minimum to earn interest is $1,000</div>
              <div className={styles.mobileDetailsItem}>
                With Eligible<sup>&dagger;</sup> Checking or $25k New Money
              </div>
              <div className={styles.mobileDetailsItem}>
                <Button id="m-cd-special-2-business-rates" {...interestRatesBtnData} />
              </div>
            </div>
          </Collapse>
          <Button id="cta-thirteen-month-cds-mobile" {...thirteenMonthCdsBtnData} />
        </article>
        {/* Business 19 Month CD Special */}
        <article id="m-19month-cd" className="mb-3 border-bottom">
          <h2>
            <strong>Business 19 Month</strong> CD Special
          </h2>
          <div className={`${styles.mobileDetailsItem} font-weight-bold`}>Minimum to earn interest is $1,000</div>
          <div
            id="cd-special-toggle-nineteen-month-cds"
            onClick={() => {
              setCollapse19MoCd(!collapse19MoCd);
            }}
            className={`${styles.mobileDetails} ${
              collapse19MoCd ? styles.mobileDetailsOpen : styles.mobileDetailsClosed
            }`}
            aria-controls="cd-special-details-nineteen-month-cds"
            aria-label="Show/Hide Details"
            aria-expanded={collapse19MoCd}
            role="button"
          ></div>
          {/* "Show/Hide Details" via CSS ::after content */}
          <Collapse in={collapse19MoCd}>
            <div id="cd-special-details-nineteen-month-cds" className="collapse">
              <div className={`${styles.mobileDetailsItem} font-weight-bold`}>$1,000 minimum to open</div>
              <div className={styles.mobileDetailsItem}>Minimum to earn interest is $1,000</div>
              <div className={styles.mobileDetailsItem}>
                With Eligible<sup>&dagger;</sup> Checking or $25k New Money
              </div>
              <div className={styles.mobileDetailsItem}>
                <Button id="m-cd-special-3-business-rates-desktop" {...interestRatesBtnData} />
              </div>
            </div>
          </Collapse>
          <Button id="cta-nineteen-month-cds-mobile" {...nineteenMonthCdsBtnData} />
        </article>
        {/* Business CDs */}
        <article id="m-business-cds" className="mb-3">
          <h2>
            <strong>Business</strong> CDs
          </h2>
          <div className={`${styles.mobileDetailsItem} font-weight-bold`}>Minimum to earn interest is $1,000</div>
          <div
            id="business-cd-toggle"
            onClick={() => {
              setCollapseBusCd(!collapseBusCd);
            }}
            className={`${styles.mobileDetails} ${
              collapseBusCd ? styles.mobileDetailsOpen : styles.mobileDetailsClosed
            }`}
            aria-controls="business-cd-details"
            aria-label="Show/Hide Details"
            aria-expanded={collapseBusCd}
            role="button"
          ></div>
          {/* "Show/Hide Details" via CSS ::after content */}
          <Collapse in={collapseBusCd}>
            <div id="business-cd-details" className="collapse">
              <div className={`${styles.mobileDetailsItem} font-weight-bold`}>$1,000 minimum to open</div>
              <div className={styles.mobileDetailsItem}>
                Terms from 3 months to 5 years. A penalty may be assessed for early withdrawal.
              </div>
              <div className={styles.mobileDetailsItem}>
                <Button id="m-business-cds-rates" {...interestRatesBtnData} />
              </div>
            </div>
          </Collapse>
          <Button id="cta-apply-at-branch-mobile" {...locationsBtnData} />
        </article>
        <p id="eligible-checking-disclaimer" className="text-sm text-muted mb-0">
          <sup>&dagger;</sup>Eligible checking accounts include Simple Business or Business Interest Checking
        </p>
      </div>
    </section>
  );
};

export default BusinessSavingsOverviewCDs;
